export const googleTagManager = (
  w: { [key: string]: any },
  d: Document,
  s: string,
  l: string,
  i: string,
): void => {
  w[l] = w[l] || [];
  w[l].push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js",
  });
  const f = d.getElementsByTagName(s)[0];
  const j: HTMLElement = d.createElement(s);
  j.setAttribute("async", "true");
  const dl = l !== "dataLayer" ? "&l=" + l : "";
  j.setAttribute("src", "https://www.googletagmanager.com/gtm.js?id=" + i + dl);
  f.parentNode?.insertBefore(j, f);
};
