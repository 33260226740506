<script setup lang="ts">
import {
  LazyAboutContact,
  LazyAboutDocuments,
  LazyAboutRequisite,
  LazyAboutStatistic,
  LazyAboutValues,
  LazyAdvantage,
  LazyBanner,
  LazyBestOffers,
  LazyCalculator,
  LazyDocuments,
  LazyFaqContent,
  LazyFaqSupport,
  LazyFormsComplain,
  LazyFormsPartner,
  LazyFormsReview,
  LazyLayoutAside,
  LazyNews,
  LazyNewsDetail,
  LazyNewsIndex,
  LazyFaqIndex,
  LazyTextPage,
  LazyPartnerLogo,
  LazyPromoBanner,
  LazyRating,
  LazyReviews,
  LazyReviewsIndex,
  LazyTextSimple,
  LazySitemap,
  LazyTab,
  LazyTags,
  LazyTextTwocolumn,
  LazyPromoIndex,
  LazyTextOffers,
  LazyBannerBg,
  LazyPromoDetail,
  LazyH1,
  LazyThirdPersons,
} from "#components";
import type { IPage } from "~/composable/pageData.types";
import { useMenuStore } from "~/stores/menu.store";
import { useSettingStore } from "~/stores/settings.store";
import { useDayjs } from "#dayjs";
import { yandexMetrica } from "~/utils/yandex";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import type { RouteLocationNormalizedGeneric } from "vue-router";
import type { Meta } from "@unhead/vue";

const route = useRoute();
const nuxtApp = useNuxtApp();
const config = useRuntimeConfig();
const menuStore = useMenuStore();
const settingStore = useSettingStore();

const { menu } = storeToRefs(menuStore);
const { settings, comeback } = storeToRefs(settingStore);

const dayjs = useDayjs();
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Moscow");

const dayjsLocaleRu: ILocale = {
  name: "ru",
  months:
    "Января_Февраля_Марта_Апреля_Мая_Июня_Июля_Августа_Сентября_Октября_Ноября_Декабря".split(
      "_",
    ),
  monthsShort: "Янв_Фев_Март_Апр_Мая_Июн_Июл_Авг_Сент_Окт_Нояб_Дек".split("_"),
  formats: {
    LT: "H:mm",
    LTS: "H:mm:ss",
    L: "DD.MM.YYYY",
    LL: "D MMMM YYYY г.",
    LLL: "D MMMM YYYY г., H:mm",
    LLLL: "dddd, D MMMM YYYY г., H:mm",
  },
  relativeTime: {
    future: "через %s",
    past: "%s назад",
  },
};

dayjs.locale(dayjsLocaleRu);

const asyncPageData = await fetchPageProperties(
  route.path,
  <string>route.query?.page ?? 1,
);
const pageData = reactive<IPage>({
  data: asyncPageData.data,
});

useSeoMeta({
  title: pageData.data?.metaTitle,
  description: pageData.data?.metaDescription,
  ogTitle: pageData.data?.metaTitle,
  ogDescription: pageData.data?.metaDescription,
  ogImage: settings.value?.data.ogImage.value,
  ogUrl: useRequestURL().toString(),
  twitterCard: "summary_large_image",
});

const metaArray = settings.value?.data.meta?.value.reduce(function (
  accumulator: Meta[],
  currentValue,
) {
  accumulator.push({
    name: currentValue.key,
    content: currentValue.value,
  });
  return accumulator;
}, []);

useHead({
  htmlAttrs: {
    lang: "ru-RU",
  },
  meta: metaArray,
  link: [
    {
      rel: "icon",
      type: "image/x-icon",
      href: settings.value?.data.favicon.value,
    },
    {
      rel: "canonical",
      href: config.public.url + route.path,
    },
  ],
});

const navigationGuard = async (
  to: RouteLocationNormalizedGeneric,
  from: RouteLocationNormalizedGeneric,
) => {
  if (from.fullPath === to.fullPath) {
    return;
  }

  if (
    from.path === to.path &&
    JSON.stringify(from.query) !== JSON.stringify(to.query)
  ) {
    const asyncPageData = await fetchPage(to.path, <string>to.query?.page ?? 1);
    pageData.data = asyncPageData?.data!;

    window.scrollTo({ top: 0 });
  }
};

onBeforeRouteUpdate(navigationGuard);

const component = {
  h1: LazyH1,
  text: LazyTextPage,
  simple_text: LazyTextSimple,
  twocolumn_text: LazyTextTwocolumn,
  best_offer: LazyBestOffers,
  banner: LazyBanner,
  banner_bg: LazyBannerBg,
  promo_banner: LazyPromoBanner,
  promo_index: LazyPromoIndex,
  promo_index_detail: LazyPromoDetail,
  faq_content: LazyFaqContent,
  tab: LazyTab,
  text_offers: LazyTextOffers,
  news: LazyNews,
  news_last: LazyNews,
  news_index: LazyNewsIndex,
  news_index_detail: LazyNewsDetail,
  faq_index: LazyFaqIndex,
  review: LazyReviews,
  reviews_index: LazyReviewsIndex,
  advantage: LazyAdvantage,
  faq_support: LazyFaqSupport,
  tags: LazyTags,
  rating: LazyRating,
  partner_logo: LazyPartnerLogo,
  calculator: LazyCalculator,
  document_categories: LazyDocuments,
  form_complain: LazyFormsComplain,
  form_review: LazyFormsReview,
  form_partner: LazyFormsPartner,
  requisite: LazyAboutRequisite,
  aside: LazyLayoutAside,
  documents: LazyAboutDocuments,
  contact: LazyAboutContact,
  statistic: LazyAboutStatistic,
  values: LazyAboutValues,
  sitemap: LazySitemap,
  third_persons: LazyThirdPersons,
};

const asideIndex = pageData!.data.layouts.findIndex(
  (layout) => layout.type === "aside",
);

nuxtApp.hook("page:finish", () => {
  if (
    !window.navigator.userAgent.includes("Chrome-Lighthouse") &&
    settings.value?.data.gtmId?.value
  ) {
    googleTagManager(
      window,
      document,
      "script",
      "dataLayer",
      settings.value.data.gtmId.value,
    );
  }
  if (
    !window.navigator.userAgent.includes("Chrome-Lighthouse") &&
    settings.value?.data.ymId?.value
  ) {
    yandexMetrica(settings.value?.data.ymId.value);
  }
  if (route.path === "/") {
    if (comeback.value === 0) {
      comeback.value += 1;
      loadComeback(document.body);
    }
  }
});
</script>
<template>
  <div class="flex flex-grow flex-col h-full">
    <header
      class="bg-white"
      :class="{ 'xl:bg-secondary-block-blue-light': route.path === '/' }"
    >
      <LayoutHeader
        :menu="menu?.data.header"
        :login-icon="settings?.data.loginIcon2"
        :login-link="settings?.data.loginLink"
        :logo="settings!.data.logo.value"
        :title="pageData!.data.title"
        :phone="settings!.data.phone"
        :phone-note="settings!.data.phoneNote"
        :phone-icon="settings!.data.phoneIcon"
      />
    </header>
    <main class="wrapper">
      <LazyLayoutBreadcrumbs
        v-if="route.path !== '/'"
        :breadcrumbs="pageData.data.breadcrumbs"
      />
      <template v-for="(layout, index) in pageData!.data.layouts" :key="index">
        <template
          v-if="
            component[layout.type as keyof Object] &&
            (asideIndex === -1 || index < asideIndex)
          "
        >
          <DelayHydration>
            <component
              :id="`block-${layout.type}-${index}`"
              :is="component[layout.type as keyof Object]"
              :items="layout.items"
              :item="layout.item"
              :title="layout.title"
              :description="layout.description"
              :config="layout.config"
            />
            <LazyLayoutPagination
              v-if="layout.items?.meta"
              :meta="layout.items?.meta"
            />
          </DelayHydration>
        </template>
      </template>

      <div
        v-if="asideIndex >= 0"
        class="flex gap-[32px] md:gap-8px max-md:flex-col md:container mx-auto w-full"
      >
        <div class="max-md:container">
          <LazyLayoutAside
            :index="asideIndex"
            :layouts="pageData!.data.layouts.slice(asideIndex)"
          />
        </div>

        <div class="flex flex-col min-w-0 wrapper pb-0 md:pb-0 xl:pb-0">
          <template
            v-for="(layout, index) in pageData!.data.layouts"
            :key="index"
          >
            <template
              v-if="
                component[layout.type as keyof Object] && index > asideIndex
              "
            >
              <DelayHydration>
                <component
                  :id="`block-${layout.type}-${index}`"
                  :is="component[layout.type as keyof Object]"
                  :items="layout.items"
                  :title="layout.title"
                  :description="layout.description"
                  :config="layout.config"
                />
                <LazyLayoutPagination
                  v-if="layout.items?.meta"
                  :meta="layout.items?.meta"
                />
              </DelayHydration>
            </template>
          </template>
        </div>
      </div>
    </main>
    <footer>
      <LayoutFooter
        :menu="menu?.data.footer"
        :title="pageData!.data.title"
        :logo="settings!.data.logoFooter?.value ?? settings!.data.logo.value"
        :logo-small="
          settings!.data.logoSmallFooter?.value ??
          settings!.data.logoSmall.value
        "
        :phone="settings!.data.phone"
        :phone-note="settings!.data.phoneNote"
        :phone-icon="settings!.data.phoneIcon"
        :footer-info="settings?.data.footerInfo"
        :footer-cookie="settings?.data.footerCookie"
        :footer-links="settings?.data.footerLinks"
      />
    </footer>
    <client-only>
      <template v-if="settings?.data.gtmId">
        <noscript
          ><iframe
            :src="`https://www.googletagmanager.com/ns.html?id=${settings?.data.gtmId.value}`"
            height="0"
            width="0"
            style="display: none; visibility: hidden"
          ></iframe
        ></noscript>
      </template>
      <template v-if="settings?.data.ymId">
        <noscript
          ><div>
            <img
              :src="`https://mc.yandex.ru/watch/${settings?.data.ymId.value}`"
              style="position: absolute; left: -9999px"
              alt=""
            /></div
        ></noscript>
      </template>
    </client-only>
  </div>
</template>

<style scoped lang="postcss">
header {
  @apply bg-white relative z-20;
}

main {
  @apply flex flex-col;
}

.wrapper {
  @apply gap-sm-block
  md:gap-md-block
  xl:gap-block
  pb-sm-block md:pb-md-block xl:pb-block relative z-10;
}
</style>
