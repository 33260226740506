import type { IPage } from "~/composable/pageData.types";
import { useMenuStore } from "~/stores/menu.store";
import { useSettingStore } from "~/stores/settings.store";
import type { IRedirect } from "~/composable/redirect.type";

export const redirect = async (path: string) => {
  const config = useRuntimeConfig();
  const data = await $fetch<IRedirect>(`/api/redirect`, {
    query: {
      path: path,
    },
  });

  if (data?.data?.url) {
    location.href = data.data.url;
  }

  return !!data?.data?.url;
};

export const fetchPage = async (
  path: string,
  page?: [] | string,
): Promise<IPage> => {
  const { data } = await useFetch<IPage>(`/api/page`, {
    query: {
      path: path,
      page: page ?? 1,
    },
  });

  return new Promise<IPage>((resolve, reject) =>
    data.value ? resolve(data.value) : reject("failed to fetch page"),
  );
};

export const fetchPageProperties = async (path: string, page?: [] | string) => {
  const menuStore = useMenuStore();
  const settingStore = useSettingStore();

  const { data } = await useAsyncData<{
    dataPage: IPage;
  }>("page", async () => {
    const [dataPage] = await Promise.all([
      fetchPage(path, page),
      settingStore.fetchSettings(),
      menuStore.fetchMenu(),
    ]);

    return { dataPage };
  });

  if (!data.value) {
    throw showError({
      statusCode: 404,
      message: "Страница не найдена",
    });
  }
  return data.value.dataPage;
};
